import React from "react";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate
} from "react-router-dom";

import { useAuthorizeSlack, useCurrentUser } from "../app/appHooks";
import AppLayout from "./privateRoutes/AppLayout";

import { PATH_NAME } from "routes/pathNames";
import * as LazyComponent from "utils/lazyComponents";
import RequireAuth from "./privateRoutes/RequireAuth";
import SuperAdminLayout from "./superAdminRoutes/superAdminLogin";
import { useIsAdminLoggedIn } from "./superAdminRoutes/hooks";

const AppRoutes = () => {
  const [tokenFetched, setTokenFetched] = useState(
    !!localStorage.getItem("token")
  );
  const adminLoggedIn = useIsAdminLoggedIn();

  const { data } = useAuthorizeSlack(setTokenFetched);

  const { data: authenticatedUser } = useCurrentUser(
    tokenFetched,
    true
  );

  const navigate = useNavigate();
  useEffect(() => {
    if ((data as any)?.error) {
      navigate(PATH_NAME.LOGIN, {
        state: { error: (data as any)?.error }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Routes>
      {/* Super Admin routes ---- DISABLED TILL PRODUCTION */}
      {adminLoggedIn ? (
        <Route element={<SuperAdminLayout />}>
          <Route
            path={PATH_NAME.ADMIN}
            element={<LazyComponent.SuperAdminPanel />}
          />
          <Route
            path="/*"
            element={<Navigate replace to={PATH_NAME.ADMIN} />}
          />
        </Route>
      ) : (
        <>
          <Route
            path={`${PATH_NAME.ADMIN}-login`}
            element={<LazyComponent.SuperAdminSignIn />}
          />
        </>
      )}
      {tokenFetched && (data?.user || authenticatedUser) ? (
        //Authorized user routes
        <Route element={<AppLayout />}>
          <Route
            path={PATH_NAME.HOME_PAGE}
            element={<LazyComponent.NewFeeds />}
          />
          <Route
            path={PATH_NAME.NEWS_FEED}
            element={<LazyComponent.NewFeeds />}
          />

          <Route
            path={PATH_NAME.LEADERBOARD}
            element={<LazyComponent.Leaderboard />}
          />

          <Route
            path={`${PATH_NAME.ACCOUNT}/:userId`}
            element={<LazyComponent.Account />}
          />
          {/* company admin Routes */}
          <Route
            element={
              <RequireAuth
                allowedRoles={["COMPANY_ADMIN"]}
              ></RequireAuth>
            }
          >
            <Route
              path={PATH_NAME.INSIGHTS}
              element={<LazyComponent.Insights />}
            />
            <Route
              path={PATH_NAME.SETTINGS}
              element={<LazyComponent.Settings />}
            />
          </Route>

          <Route
            path={PATH_NAME.HELP_CENTER}
            element={<LazyComponent.HelpCenter />}
          />
          <Route
            path="/*"
            element={<Navigate replace to={PATH_NAME.HOME_PAGE} />}
          />
        </Route>
      ) : (
        //Unauthorized user routes
        <>
          <Route
            path={PATH_NAME.LOGIN}
            element={<LazyComponent.SignIn />}
          />
          <Route path="/*" element={<LazyComponent.SignIn />} />
        </>
      )}
      {/*Public Routes*/}
      <Route
        path={PATH_NAME.LANDING_PAGE}
        element={<LazyComponent.LandingPage />}
      />
      {/* <Route
        path={`${PATH_NAME.PUBLIC_PROFILE}/:userId`}
        element={<LazyComponent.PublicProfile />}
      /> */}
      <Route
        path={PATH_NAME.PRIVACY_POLICY}
        element={<LazyComponent.PrivacyPolicy />}
      />
      <Route
        path={PATH_NAME.TERMS_OF_SERVICE}
        element={<LazyComponent.TermsOfService />}
      />
    </Routes>
  );
};

export default AppRoutes;
