import { lazy } from "react";

export const Leaderboard = lazy(() => import("pages/leaderboard"));
export const NewFeeds = lazy(() => import("pages/newsFeed"));
export const Account = lazy(() => import("pages/account"));
export const Settings = lazy(() => import("pages/settings"));
export const SignIn = lazy(() => import("pages/signIn"));
export const LandingPage = lazy(() => import("pages/landingPage"));
export const Insights = lazy(() => import("pages/insights"));
export const PublicProfile = lazy(
  () => import("pages/publicProfile")
);
export const HelpCenter = lazy(() => import("pages/helpCenter"));
export const SuperAdminPanel = lazy(() => import("pages/superAdmin"));
export const SuperAdminSignIn = lazy(
  () => import("pages/superAdminLogin")
);
export const SettingsCompanyValues = lazy(
  () => import("pages/settings/companyValuesView")
);
export const ScheduleReport = lazy(
  () => import("pages/settings/scheduleReportView")
);
export const SettingsMembers = lazy(
  () => import("pages/settings/membersView")
);
export const SettingsGifsCatalog = lazy(
  () => import("pages/settings/gifsCatelogView")
);
export const SettingsSubscription = lazy(
  () => import("pages/settings/subscriptionView")
);
export const PrivacyPolicy = lazy(
  () => import("pages/privacyPolicy")
);
export const TermsOfService = lazy(
  () => import("pages/termsOfService")
);
