import { jwtDecode } from "jwt-decode";

// Service to check authentication for user and to signOut
const Auth = {
  signOut() {
    localStorage.removeItem("token");
  },
  isAuth() {
    return localStorage.getItem("token");
  },
  userAccessToken() {
    return localStorage.getItem("token");
  },
  accessToken() {
    const token = localStorage.getItem("token");
    const adminToken = localStorage.getItem("admin-token");
    return adminToken || token;
  },
  superAdminAccessToken() {
    return localStorage.getItem("admin-token");
  },
  getTeamId() {
    const token = this.accessToken();
    if (!token) return null;
    try {
      const decoded = jwtDecode(token);
      return decoded.teamId;
    } catch (error) {
      return null;
    }
  }
};
export default Auth;
